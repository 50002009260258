import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment/moment'
import AppStore from './AppStore'
import BaseStore from './BaseStore'

export class EquipmentStore extends BaseStore {
  equipmentTmp = {
    id: null,
    contractor_id: null,
    contractor: { data: {} },
    product_id: null,
    product: null,
    contract_number: null,
    installation_date: null,
    data: { comment: '' },
    is_add: true,
  }
  @observable editEquipment = null
  @observable contractor = null
  @observable contractor_id = null

  constructor() {
    super()
    makeObservable(this)
  }

  @computed
  get equipment() {
    return this.editEquipment ? this.editEquipment : this.equipmentTmp
  }

  @computed
  get isEditEquipment() {
    return !!this.editEquipment
  }

  @action
  onSelectContractor = () => {
    this.editEquipment = {
      ...this.equipmentTmp,
      contractor_id: this.contractor_id,
      contractor: this.contractor,
    }
    this.contractor = this.contractor_id = null
  }

  @action
  selectEditEquipment = equipment => {
    this.editEquipment = equipment
  }

  checkEquipment(is_submit = false, select_first = false) {
    if (this.editEquipment && !this.editEquipment.product_id && select_first) {
      const product = this.products[0]
      if (product) {
        this.editEquipment.product = product
        this.editEquipment.product_id = product.id
      }
    }
    if (
      this.editEquipment &&
      (is_submit || !this.editEquipment.is_add) &&
      !this.editEquipment.product_id
    ) {
      AppStore.showWarning('Выберите Оборудование')
      return false
    }
    return true
  }

  @action
  closeEditEquipment = () => {
    if (this.checkEquipment()) this.editEquipment = null
  }

  @action
  removeEditEquipment = () => {
    if (this.editEquipment && this.item.equipments) {
      const { id, contractor_id, product_id } = this.editEquipment
      const index = this.item.equipments.findIndex(e =>
        id
          ? e.id === id
          : e.contractor_id === contractor_id && e.product_id === product_id,
      )
      index !== -1 && this.item.equipments.splice(index, 1)
    }
    this.editEquipment = null
  }

  @action
  submitEditEquipment = (select_first = false) => {
    if (this.checkEquipment(true, select_first)) {
      if (this.editEquipment.is_add && this.item.equipments) {
        delete this.editEquipment.is_add
        this.item.equipments.push(this.editEquipment)
      }
      this.closeEditEquipment()
    }
  }

  @action
  setSelectedProduct = p => (this.editEquipment.product = p)

  @computed
  get products() {
    return this.item.products
      ? this.item.products.reduce(this.reduceProductOptions, [])
      : []
  }

  reduceProductOptions = (products, product) => {
    if (!this.item.equipments) return products
    let count = product.count
    const skip_id =
      this.editEquipment && this.editEquipment.product_id
        ? this.editEquipment.product_id
        : -1
    for (const e of this.item.equipments) {
      if (e.product_id === product.id) {
        if (e.product_id === skip_id) break
        else count--
      }
    }
    count > 0 &&
      products.push({
        ...product,
        serial_number: product.data.serial_number || product.identity,
      })
    return products
  }

  @action
  setStatusDone = () => {
    this.item.data.installation_status = 'done'
  }

  @action
  setStatusEdit = () => {
    this.item.data.installation_status = 'edit'
  }

  isReadOnly() {
    return this.item.data?.installation_status === 'done' || super.isReadOnly()
  }
}

const store = new EquipmentStore()
export default store
